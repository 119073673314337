import React, { useState, useEffect } from 'react';
import { useAuth } from '../context/AuthContext';
import './AdventurerDashboard.css';

const AdventurerDashboard = ({ messages, lastSession }) => {
  const { currentUser, logout } = useAuth();
  const [activeTab, setActiveTab] = useState('overview');
  const [avatarUrl, setAvatarUrl] = useState(currentUser?.avatar || '');
  const [isUploading, setIsUploading] = useState(false);
  const [uploadError, setUploadError] = useState('');
  const [characterSheet, setCharacterSheet] = useState(null);
  const [isLoadingCharacter, setIsLoadingCharacter] = useState(false);
  const [characterError, setCharacterError] = useState('');
  
  // Use production URL when in production, localhost for development
  const API_URL = window.location.hostname === 'localhost' 
    ? 'http://localhost:3001/api'
    : 'https://firstveil.com/api';  // Replace with your actual production API URL
  
  // Fetch avatar when component mounts
  useEffect(() => {
    fetchAvatar();
    // If user is adventurer, fetch character sheet
    if (currentUser?.role === 'adventurer') {
      fetchCharacterSheet();
    }
  }, []);

  // Fetch user's avatar from the server
  const fetchAvatar = async () => {
    try {
      if (!currentUser) return;
      
      const response = await fetch(`${API_URL}/avatar`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (response.ok) {
        const imageBlob = await response.blob();
        const imageUrl = URL.createObjectURL(imageBlob);
        setAvatarUrl(imageUrl);
      } else {
        console.error('Failed to fetch avatar');
      }
    } catch (error) {
      console.error('Error fetching avatar:', error);
    }
  };

  // Fetch user's character sheet
  const fetchCharacterSheet = async () => {
    try {
      setIsLoadingCharacter(true);
      setCharacterError('');
      
      const response = await fetch(`${API_URL}/character-sheet`, {
        method: 'GET',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to fetch character sheet');
      }
      
      const data = await response.json();
      setCharacterSheet(data);
    } catch (err) {
      console.error('Error fetching character sheet:', err);
      setCharacterError(err.message || 'An error occurred while fetching character sheet');
    } finally {
      setIsLoadingCharacter(false);
    }
  };

  // Calculate ability modifier
  const getAbilityModifier = (score) => {
    if (!score) return 0;
    return Math.floor((score - 10) / 2);
  };

  // Render HTML content safely
  const renderHtml = (html) => {
    return { __html: html };
  };

  // Handle avatar upload
  const handleAvatarUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;
    
    // Check file size (max 2MB)
    if (file.size > 2 * 1024 * 1024) {
      setUploadError('File too large. Maximum size is 2MB.');
      return;
    }
    
    // Check file type
    if (!file.type.match('image.*')) {
      setUploadError('Only image files are allowed.');
      return;
    }
    
    setIsUploading(true);
    setUploadError('');
    
    try {
      // Create FormData object for file upload
      const formData = new FormData();
      formData.append('avatar', file);
      
      // Upload the file to the server
      const response = await fetch(`${API_URL}/upload/avatar`, {
        method: 'POST',
        headers: {
          'x-auth-token': localStorage.getItem('auth_token')
        },
        body: formData
      });
      
      if (!response.ok) {
        const errorData = await response.json().catch(() => ({}));
        throw new Error(errorData.message || 'Failed to upload avatar');
      }
      
      // Update the avatar preview with the new image
      const imageUrl = URL.createObjectURL(file);
      setAvatarUrl(imageUrl);
    } catch (err) {
      setUploadError(err.message || 'An error occurred during upload');
      console.error('Error uploading avatar:', err);
    } finally {
      setIsUploading(false);
      
      // Reset the file input
      e.target.value = '';
    }
  };

  // Format date string
  const formatDate = (dateString) => {
    if (!dateString) return 'N/A';
    const date = new Date(dateString);
    return date.toLocaleDateString('en-US', { year: 'numeric', month: 'long', day: 'numeric' });
  };

  // Handle tab selection
  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  // Handle logout
  const handleLogout = () => {
    logout();
  };

  // Handle character sheet upload
  const handleCharacterSheetUpload = async (e) => {
    const file = e.target.files[0];
    if (!file) return;

    // Check file type
    if (!file.name.endsWith('.json')) {
      setCharacterError('Invalid file type. Please upload a JSON file.');
      return;
    }

    // Check file size (max 5MB)
    if (file.size > 5 * 1024 * 1024) {
      setCharacterError('File too large. Maximum size is 5MB.');
      return;
    }

    setIsLoadingCharacter(true);
    setCharacterError('');
    
    const formData = new FormData();
    formData.append('characterSheet', file);

    try {
      const response = await fetch(`${API_URL}/adventurer/upload/character-sheet`, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: formData
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to upload character sheet');
      }

      // Successfully uploaded, now fetch the character sheet
      await fetchCharacterSheet();
    } catch (error) {
      console.error('Error uploading character sheet:', error);
      setCharacterError(error.message || 'Failed to upload character sheet');
    } finally {
      setIsLoadingCharacter(false);
    }
  };

  // Render character sheet content
  const renderCharacterSheet = () => {
    if (isLoadingCharacter) {
      return <div className="loading-character">Loading character sheet...</div>;
    }

    if (characterError) {
      return <div className="character-error">{characterError}</div>;
    }

    if (!characterSheet) {
      return (
        <div className="no-character-sheet">
          <h4>No Character Sheet Found</h4>
          <p>Upload your character sheet to see it displayed here.</p>
          <label className="upload-character-button">
            Upload Character Sheet
            <input
              type="file"
              accept=".json"
              onChange={handleCharacterSheetUpload}
            />
          </label>
        </div>
      );
    }

    // Create a string-based representation of the character sheet to avoid DOM conflicts
    const renderCharacterContent = () => {
      let html = '';
      
      // Character name and basic info
      html += `<h2>${characterSheet.name || 'Unnamed Character'}</h2>`;
      
      html += '<div class="character-details">';
      html += `
        <div class="character-detail">
          <span class="detail-label">Class</span>
          <span class="detail-value">${characterSheet.system?.details?.class || 'Unknown'}</span>
        </div>
        <div class="character-detail">
          <span class="detail-label">Level</span>
          <span class="detail-value">${characterSheet.system?.details?.level || '1'}</span>
        </div>
        <div class="character-detail">
          <span class="detail-label">Race</span>
          <span class="detail-value">${characterSheet.system?.details?.race || 'Unknown'}</span>
        </div>
        <div class="character-detail">
          <span class="detail-label">Background</span>
          <span class="detail-value">${characterSheet.system?.details?.background || 'Unknown'}</span>
        </div>
      `;
      html += '</div>';
      
      // Abilities
      html += '<h3>Abilities</h3>';
      html += '<div class="abilities-container">';
      
      if (characterSheet.system?.abilities) {
        Object.entries(characterSheet.system.abilities).forEach(([key, ability]) => {
          const mod = getAbilityModifier(ability.value);
          html += `
            <div class="ability-score">
              <div class="ability-name">${key.toUpperCase()}</div>
              <div class="ability-value">${ability.value || 10}</div>
              <div class="ability-modifier">${mod}</div>
            </div>
          `;
        });
      }
      
      html += '</div>';
      
      // Skills
      html += '<h3>Skills</h3>';
      html += '<div class="skills-container">';
      
      if (characterSheet.system?.skills) {
        // Sort skills alphabetically
        const sortedSkills = Object.entries(characterSheet.system.skills)
          .sort(([keyA], [keyB]) => keyA.localeCompare(keyB));
          
        sortedSkills.forEach(([key, skill]) => {
          html += `
            <div class="skill-item">
              <div class="skill-proficient ${skill.proficient ? 'active' : ''}"></div>
              <div class="skill-name">${key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}</div>
              <div class="skill-modifier">${skill.mod ? `+${skill.mod}` : '+0'}</div>
            </div>
          `;
        });
      }
      
      html += '</div>';
      
      // Combat Stats
      html += '<h3>Combat</h3>';
      html += '<div class="combat-stats">';
      html += `
        <div class="combat-stat">
          <div class="stat-name">Armor Class</div>
          <div class="stat-value">${characterSheet.system?.attributes?.ac?.value || '10'}</div>
        </div>
        <div class="combat-stat">
          <div class="stat-name">Initiative</div>
          <div class="stat-value">${characterSheet.system?.attributes?.init?.total || '+0'}</div>
        </div>
        <div class="hp-container combat-stat">
          <div class="stat-name">Hit Points</div>
          <div class="stat-value">
            ${characterSheet.system?.attributes?.hp?.value || '0'} / ${characterSheet.system?.attributes?.hp?.max || '0'}
          </div>
        </div>
      `;
      html += '</div>';
      
      // Equipment
      html += '<h3>Equipment</h3>';
      html += '<div class="equipment-list">';
      
      if (characterSheet.items) {
        const equipment = characterSheet.items.filter(item => 
          item.type === 'weapon' || item.type === 'equipment' || item.type === 'consumable'
        );
        
        equipment.forEach(item => {
          const description = item.system?.description?.value
            ? item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")
            : 'No description available';
            
          html += `
            <div class="equipment-item">
              <div class="equipment-name">${item.name}</div>
              <div class="equipment-description">
                <div>${description}</div>
              </div>
            </div>
          `;
        });
      }
      
      html += '</div>';
      
      // Features
      html += '<h3>Features & Traits</h3>';
      html += '<div class="features-list">';
      
      if (characterSheet.items) {
        const features = characterSheet.items.filter(item => 
          item.type === 'feat' || item.type === 'feature'
        );
        
        features.forEach(item => {
          const description = item.system?.description?.value
            ? item.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")
            : 'No description available';
            
          html += `
            <div class="feature-item">
              <div class="feature-name">${item.name}</div>
              <div class="feature-description">
                <div>${description}</div>
              </div>
            </div>
          `;
        });
      }
      
      html += '</div>';
      
      // Spells
      if (characterSheet.items && characterSheet.items.some(item => item.type === 'spell')) {
        html += '<h3>Spells</h3>';
        html += '<div class="spell-list">';
        
        const spells = characterSheet.items.filter(item => item.type === 'spell');
        
        spells.forEach(spell => {
          const description = spell.system?.description?.value
            ? spell.system.description.value.replace(/<\/?[^>]+(>|$)/g, " ")
            : 'No description available';
            
          html += `
            <div class="spell-item">
              <div class="spell-name">${spell.name} (${spell.system?.level || '0'})</div>
              <div class="spell-description">
                <div>${description}</div>
              </div>
            </div>
          `;
        });
        
        html += '</div>';
      }
      
      return html;
    };

    return (
      <div className="character-sheet-content">
        <div className="character-sheet-refresh">
          <button 
            className="refresh-button" 
            onClick={fetchCharacterSheet}
            disabled={isLoadingCharacter}
          >
            Refresh Character Sheet
          </button>
        </div>
        
        {/* Use a div with innerHTML instead of React components */}
        <div 
          id="raw-character-sheet" 
          dangerouslySetInnerHTML={{ __html: renderCharacterContent() }} 
        />
      </div>
    );
  };
  
  // Main render
  return (
    <div className="adventurer-dashboard">
      <div className="dashboard-header">
        <h1>Adventurer Dashboard</h1>
        <button className="logout-button" onClick={handleLogout}>Logout</button>
      </div>
      
      <div className="dashboard-tabs">
        <button 
          className={`tab-button ${activeTab === 'overview' ? 'active' : ''}`}
          onClick={() => handleTabClick('overview')}
        >
          Overview
        </button>
        <button 
          className={`tab-button ${activeTab === 'character' ? 'active' : ''}`}
          onClick={() => handleTabClick('character')}
        >
          Character Sheet
        </button>
        <button 
          className={`tab-button ${activeTab === 'profile' ? 'active' : ''}`}
          onClick={() => handleTabClick('profile')}
        >
          Profile
        </button>
      </div>
      
      <div className="tab-content">
        {activeTab === 'overview' && (
          <div className="overview-tab">
            <h3>Welcome, {currentUser?.displayName || currentUser?.email || 'Adventurer'}</h3>
            
            <div className="gm-messages">
              <h4>Messages from the Game Master</h4>
              {messages && messages.length > 0 ? (
                <div className="message-list">
                  {messages.map((message, index) => (
                    <div key={index} className="gm-message">
                      <div className="message-header">
                        <span className="message-from">From: {message.from}</span>
                        <span className="message-date">{formatDate(message.date)}</span>
                      </div>
                      <div 
                        className="message-content"
                        dangerouslySetInnerHTML={renderHtml(message.content)}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div className="no-messages">
                  <p>No messages from the GM yet.</p>
                </div>
              )}
            </div>
            
            <div className="last-session">
              <h4>Last Session Recap</h4>
              {lastSession ? (
                <div className="session-recap">
                  <div 
                    className="message-content"
                    dangerouslySetInnerHTML={renderHtml(lastSession.content)}
                  />
                  <span className="message-date">{formatDate(lastSession.date)}</span>
                </div>
              ) : (
                <div className="no-recap">
                  <p>No session recap available.</p>
                </div>
              )}
            </div>
          </div>
        )}
        
        {activeTab === 'character' && (
          <div className="character-sheet-tab">
            {renderCharacterSheet()}
          </div>
        )}
        
        {activeTab === 'profile' && (
          <div className="profile-tab">
            <h3>Your Profile</h3>
            
            <div className="avatar-section">
              <div className="current-avatar">
                {avatarUrl ? (
                  <img src={avatarUrl} alt="Your avatar" />
                ) : (
                  <div className="avatar-placeholder">
                    <span>{currentUser?.displayName?.[0] || currentUser?.email?.[0] || '?'}</span>
                  </div>
                )}
              </div>
              
              <div className="avatar-upload">
                <h4>Update Avatar</h4>
                <p>Upload a new avatar image (max 2MB).</p>
                
                <label className="upload-button">
                  Choose Image
                  <input 
                    type="file" 
                    accept="image/*" 
                    onChange={handleAvatarUpload} 
                    disabled={isUploading}
                  />
                </label>
                
                {isUploading && <div className="loading-spinner"></div>}
                {uploadError && <div className="upload-error">{uploadError}</div>}
              </div>
            </div>
            
            <div className="profile-info">
              <div className="info-item">
                <div className="info-label">Name</div>
                <div className="info-value">{currentUser?.displayName || 'Not set'}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Email</div>
                <div className="info-value">{currentUser?.email}</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Role</div>
                <div className="info-value role-adventurer">Adventurer</div>
              </div>
              
              <div className="info-item">
                <div className="info-label">Member Since</div>
                <div className="info-value">{formatDate(currentUser?.createdAt)}</div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default AdventurerDashboard; 